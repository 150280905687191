<template>
  <div class="areawrap">
    <b-row class="mb-4">
      <b-col cols="8">
        <h2>
          Article
        </h2>
        <hr>
        <b-form-group
          label="Title"
          class="mb-6"
          :state="$v.form.$anyDirty ? !$v.form.title.$anyError : null"
        >
          <b-form-input v-model="form.title" placeholder="Enter Title" />
          <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.title.required : null">
            title is required
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          label="Content"
          :state="$v.form.$anyDirty ? !$v.form.file_uri.$anyError : null"
        >
          <template v-if="pdfUrl">
            <div class="pdf-wrap">
              <template v-for="page in pdfTotalPage">
                <pdf :key="page" :src="pdfUrl" :page="page" />
              </template>
            </div>
            <b-button variant="danger" class="btn-width mt-4" @click="onFileCancel">
              CANCEL
            </b-button>
          </template>
          <template v-else>
            <label for="file" class="custom-file">
              <img alt="file upload" src="~@/assets/images/icon-others-file.png">
              <span>Darg and Drop PDF file</span>
            </label>
          </template>
          <input id="file" type="file" class="d-none" accept=".pdf" @change="onFilePicked">
          <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.file_uri.required : null">
            file is required
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Publish Target"
              :state="$v.form.$anyDirty ? !$v.form.target.$anyError : null"
            >
              <b-form-select
                v-model="form.target"
                :options="targetList"
                :state="$v.form.$anyDirty ? !$v.form.target.$anyError : null"
              >
                <template #first>
                  <b-form-select-option :value="null">
                    Target
                  </b-form-select-option>
                </template>
              </b-form-select>
              <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.target.required : null">
                Publish target is required
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Model"
              :state="$v.form.$anyDirty ? !$v.form.model.$anyError : null"
            >
              <b-form-select
                v-model="form.model"
                :options="modelList"
                :state="$v.form.$anyDirty ? !$v.form.model.$anyError : null"
              >
                <template #first>
                  <b-form-select-option :value="null">
                    Model
                  </b-form-select-option>
                </template>
              </b-form-select>
              <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.model.required : null">
                model is required
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Category"
              :state="$v.form.$anyDirty ? !$v.form.category.$anyError : null"
            >
              <b-form-select
                v-model="form.category"
                :options="categoryList"
                :state="$v.form.$anyDirty ? !$v.form.category.$anyError : null"
              >
                <template #first>
                  <b-form-select-option :value="null">
                    Category
                  </b-form-select-option>
                </template>
              </b-form-select>
              <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.category.required : null">
                category is required
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Version"
              class="mb-6"
              :state="$v.form.$anyDirty ? !$v.form.version.$anyError : null"
            >
              <b-form-input v-model="form.version" placeholder="Enter Version" />
              <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.version.required : null">
                version is required
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <div class="d-flex">
      <b-button variant="primary" class="btn-width mr-4" @click="submit">
        PUBLISH
      </b-button>
      <b-button variant="light" class="btn-width" :to="{ name: 'FaqList' }">
        CANCEL
      </b-button>
    </div>
  </div>
</template>

<script>
import pdf from 'vue-pdf'
import modelList from '@/common/modelList'
import categoryList from '@/common/categoryList'
import targetList from '@/common/targetList'
import { required } from 'vuelidate/lib/validators'
import { createFaq, updateFaq, uploadFile, getFile } from '@/api/faq'

export default {
  name: 'FaqAction',
  components: {
    pdf
  },
  props: {
    action: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      form: {
        model: null,
        title: '',
        version: '',
        category: null,
        file_uri: '',
        target: null
      },
      pdfUrl: '',
      pdfTotalPage: 0
    }
  },
  validations () {
    return {
      form: {
        model: { required },
        title: { required },
        version: { required },
        category: { required },
        target: { required },
        file_uri: { required }
      }
    }
  },
  computed: {
    modelList () {
      return modelList
    },
    categoryList () {
      return categoryList
    },
    targetList () {
      return targetList
    },
    isEdit () {
      return this.action === 'edit'
    }
  },
  created () {
    if (this.isEdit) {
      this.form = { ...this.$store.state.selectedFaq }
      if (this.form.file_uri) {
        this.loadFile(this.form.file_uri)
      }
    }
  },
  methods: {
    async onFilePicked (e) {
      if (e.target.files.length === 0) return
      const file = e.target.files[0]
      this.form.file_uri = file
      this.pdfUrl = URL.createObjectURL(file)
      const pdfData = pdf.createLoadingTask(this.pdfUrl)
      const pdfDetail = await pdfData.promise
      this.pdfTotalPage = pdfDetail.numPages
    },
    onFileCancel () {
      this.form.file_uri = ''
      this.pdfUrl = ''
      this.pdfTotalPage = 0
    },
    loadFile (uri) {
      getFile(uri).then(async (res) => {
        this.pdfUrl = URL.createObjectURL(res.data)
        const pdfData = pdf.createLoadingTask(this.pdfUrl)
        const pdfDetail = await pdfData.promise
        this.pdfTotalPage = pdfDetail.numPages
      })
    },
    async submit () {
      this.$v.form.$touch()
      if (this.$v.form.$anyError) return

      // check upload file
      if (this.form.file_uri instanceof Blob) {
        await uploadFile(this.form.file_uri).then((res) => {
          this.form.file_uri = res.data.data.file_uri
        })
      }
      if (!this.isEdit) {
        createFaq(this.form).then(() => {
          this.$router.push({ name: 'FaqList' })
        })
      } else {
        updateFaq(this.form).then(() => {
          this.$router.push({ name: 'FaqList' })
        })
      }
    }
  }
}
</script>

<style scoped>
.custom-file {
  display: flex;
  flex-direction: column;
  height: 500px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 0.75rem;
  box-shadow: inset -10px -10px 10px 0 rgba(255, 255, 255, 0.7), inset 10px 10px 10px 0 rgba(174, 174, 192, 0.2);
}

.pdf-wrap {
  height: 500px;
  overflow: auto;
}
</style>
